import { ConfigsManager } from '../../infra/configs/configs.manager';

export const removeCookie = (name: string) => {
  const { brand } = ConfigsManager.getInstance();

  const brandDomains = {
    wyden: 'wyden.com.br',
    estacio: 'estacio.br',
    ibmec: 'ibmec.br',
    idomed: 'idomed.com.br',
  } as Record<string, string>;

  document.cookie = `${name}=; path=/; domain=${brandDomains[brand]}; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=None; Secure`;
};
