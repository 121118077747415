import { createEvent, createStore } from 'effector';
import { Curso } from '../../models/usuario.model';
import { usuarioSelecionado } from '../usuario/usuario.store';

export const atualizaCurso = createEvent<Curso>();
export const loadCurso = createEvent();

export const $curso = createStore<Curso | null>(null)
  .on(usuarioSelecionado, (_, value) => value.curso)
  .on(atualizaCurso, (_, value) => value)
  .on(loadCurso, () => {
    const sessionResult = sessionStorage.getItem('cursoSelecionado');
    if (sessionResult) {
      return JSON.parse(sessionResult);
    }
    return null;
  });
