import { IdExterno } from '../../models/usuario.model';

const getStoredValues = () => {
  return {
    storedInscricao: localStorage.getItem('idExterno.inscricao'),
    storedCandidato: localStorage.getItem('idExterno.candidato'),
    storedAtila: localStorage.getItem('idExterno.atila'),
    storedOrigem: localStorage.getItem('origem'),
  };
};

const getCookie = (valor: string) => {
  const cookieValor = document.cookie.split('; ').find((cookie) => cookie.startsWith(`${valor}=`));
  return cookieValor ? JSON.parse(decodeURIComponent(cookieValor.split('=')[1])) : null;
};

const getQueryParams = (searchParams: URLSearchParams) => {
  return {
    loginHint: searchParams.get('login_hint') ?? localStorage.getItem('login_hint') ?? getCookie('userInfo')?.email,
    forceLogin: searchParams.get('force_login'),
    logout: !!searchParams.get('signout'),
    candidatoQuery: searchParams.get('idExterno.candidato'),
    inscricaoQuery: searchParams.get('idExterno.inscricao'),
    atilaQuery: searchParams.get('idExterno.atila'),
    origemQuery: searchParams.get('origem') ?? getCookie('userInfo')?.origem,
  };
};

const updateLocalStorage = (
  candidato: number,
  inscricao: number,
  atila: string | null,
  origem: string | null,
  isLoginURL: boolean,
) => {
  localStorage.setItem('idExterno.candidato', isNaN(candidato) ? '' : candidato.toString());
  localStorage.setItem('idExterno.inscricao', isNaN(inscricao) ? '' : inscricao.toString());
  localStorage.setItem('idExterno.atila', atila ?? '');

  if (isLoginURL) {
    localStorage.setItem('origem', !origem ? '' : origem);
  }
};

const updateSessionStorage = (forceLogin: string | null) => {
  if (forceLogin) {
    sessionStorage.setItem('force_login', forceLogin);
  }
};

const updateLoginHint = (loginHint: string | null) => {
  if (loginHint) {
    localStorage.setItem('login_hint', loginHint);
  }
};

export const authSearchParams = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const { storedInscricao, storedCandidato, storedAtila, storedOrigem } = getStoredValues();
  const { loginHint, forceLogin, logout, candidatoQuery, inscricaoQuery, atilaQuery, origemQuery } =
    getQueryParams(searchParams);

  let candidato = storedCandidato ? +storedCandidato : NaN;
  let inscricao = storedInscricao ? +storedInscricao : NaN;
  let origem = storedOrigem;
  const isLoginURL = document.location.pathname.includes('login');

  if (candidatoQuery || inscricaoQuery || origemQuery) {
    candidato = candidatoQuery ? +candidatoQuery : candidato;
    inscricao = inscricaoQuery ? +inscricaoQuery : inscricao;
    origem = origemQuery ?? origem;
  }

  const atila = atilaQuery ?? storedAtila;

  if (isLoginURL && (candidatoQuery || inscricaoQuery)) {
    origem = null;
  }

  updateLocalStorage(candidato, inscricao, atila, origem, isLoginURL);
  updateSessionStorage(forceLogin);
  updateLoginHint(loginHint);

  const idExterno: IdExterno = {
    inscricao: isNaN(inscricao) ? null : inscricao,
    candidato: isNaN(candidato) ? null : candidato,
    atila,
  };

  const isLead = origem?.toLowerCase() === 'portal';

  return { loginHint, forceLogin, idExterno, logout, isLead };
};
